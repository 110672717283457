body {
  margin: 0;
  /* font-family: 'Helvetica Neue' !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

#root {
  display: flex;
}

.str-chat__channel-list {
  width: 30%;
}

.str-chat__channel {
  width: 100%;
}

.str-chat__thread {
  width: 45%;
}

.str-chat {
  /* margin-top: 70px; */
  height: 100%;
}

.mic-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  /* Adjust the size of the circle */
  height: 50px;
  /* Adjust the size of the circle */
  border-radius: 50%;
  /* Makes the div circular */
  background-color: #EEEEEE;
  /* Default background color */
  cursor: pointer;
  margin-left: 20px;
}

.mic-circle.listening {
  background-color: #FFEEEE;
  /* Change background color when listening */
}

.mic-circle.listening:hover {
  background-color: red;
  /* Change background color when listening */
}

.mic-circle.listening:hover .fa-microphone {
  color: white;
}

.mic-circle:hover {
  background-color: #007bff;
  /* Blue color on hover */
}

.mic-circle:hover .fa-microphone {
  color: white;
}

/* Normal state */
.fa-microphone {
  color: gray;
  font-size: 25px;
}

/* Listening state */
.fa-microphone.listening {
  color: red;
}

/* Hover state */
.fa-microphone:hover {
  color: white;
}

.group-card-view {
  box-shadow: 0 1px 2px 3px var(--primary) !important;
  /* color: var() !important;
  background-color: white !important; */
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
  overflow: hidden !important;
  border-radius: 10px !important;
  border-width: 3px !important;

}

.group-card-view:hover {
  transform: scale(1.01) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 4px 3px var(--bluedark) !important;
  border-width: 4px !important;
}

.str-chat__date-separator {
  /* display: flex; */
  padding: 0 !important;
  /* align-items: center; */
}

/* In your CSS file */
.custom-navbar {
  background-color: var(--bluelightest);
  /* other styles */
}

.world-card-view {
  box-shadow: 0 1px 2px 2px var(--primary) !important;
  /* color: var() !important;
  background-color: white !important; */
  opacity: 0.9 !important;
  transition: all 0.15s ease 0s !important;
  height: 100% !important;
  overflow: hidden !important;
  border-radius: 10px !important;
  border-width: 3px !important;

}

.world-card-view:hover {
  transform: scale(1.01) !important;
  opacity: 1 !important;
  overflow: hidden !important;
  box-shadow: 0 2px 4px 3px var(--bluedark) !important;
  border-width: 3px !important;
}

/* Customizing the scrollbar for WebKit browsers */
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: var(--bluelight);
}

body::-webkit-scrollbar-thumb:hover {
  background: var(--blue);
}

/* For Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: var(--bluelight) #f1f1f1;
}

body:hover {
  scrollbar-color: var(--blue) #f1f1f1;
}

@keyframes blinkingText {
  0% {
    color: var(--primaryred);
  }

  50% {
    color: transparent;
  }

  100% {
    color: var(--primaryred);
  }
}

.blink-text {
  animation: blinkingText 2.0s infinite;
}

.wordcount-dropdown-toggle {
  background-color: var(--white);
  border-color: var(--blue);
  color: var(--blue);
}

.wordcount-dropdown-toggle:hover,
.wordcount-dropdown-toggle:focus {
  background-color: var(--blue);
  border-color: var(--blue)
}
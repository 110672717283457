.index-focus {
  text-align: center;
}

form {
  width: 300px;
  /* Set a width for the form */
  margin: 0 auto;
  /* Center the form horizontally */
  padding: 20px;
  /* Add some padding inside the form */
  background-color: #f7f7f7;
  /* Set a background color for the form */
  border: 1px solid #ccc;
  /* Add a border around the form */
  border-radius: 5px;
  /* Add rounded corners to the form */
}

/* Style labels for form elements */
label {
  display: block;
  /* Display labels as block elements, one per line */
  margin-bottom: 5px;
  /* Add spacing between labels and input fields */
  font-weight: bold;
  /* Make labels bold */
}

.page2-container {
  padding: 20px;
}

.page2-heading {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.focus-group-list {
  display: flex;
  flex-wrap: wrap;
}

.focus-group-tile {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  width: 300px;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.focus-group-name {
  font-size: 18px;
}

.participant-count,
.max-limit,
.meeting-date,
.moderator {
  font-size: 14px;
  margin-top: 5px;
  color: #555;
}

.page3-container {
  padding: 20px;
}

.page3-heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.topic-select {
  margin-bottom: 10px;
}

.topic-select select,
.topic-select input,
.questions-heading input,
.questions-heading button {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}

.questions-heading button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  cursor: pointer;
}

.questions-list ul {
  list-style: none;
  padding: 0;
}

.questions-list li {
  margin-bottom: 5px;
}
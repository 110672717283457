.str-chat {
    --str-chat__font-family: "Open Sans", sans-serif;
    --str-chat__primary-color: var(--blue);
    --str-chat__primary-color-low-emphasis: var(--red);
    --str-chat__active-primary-color: var(--red);
    --str-chat__primary-surface-color: var(--bluelighter);
    /* --str-chat__primary-surface-color-low-emphasis: var(--red); */
    --str-chat__avatar-border-radius: 30%;
    --str-chat__avatar-background-color: var(--blue);
    --str-chat__avatar-color: var(--white);
}

.moderator-message .str-chat__message-bubble {
    background-color: var(--primaryredlight);
    /* border-color: var(--blue);
  color: var(--blue); */
}

.moderator-message .str-chat__avatar-fallback {
    background-color: var(--primaryred);
}

.moderator-message .str-chat__message-text p {
    /* color: white; */
    font-weight: bold;
}

.moderator-message .str-chat__message-simple-name::after {
    content: " (Moderator)";
    color: var(--primaryred);
    font-weight: bold;
}